import styled from '@emotion/styled'
import Button from 'components/Button'
import RedirectAuthed from 'components/RedirectAuthed'
import ZalandoLogo from 'components/ZalandoLogo'
import useAuth, { AuthStatus } from 'hooks/useAuth'
import useLogin from 'hooks/useLogin'
import useRegister from 'hooks/useRegister'
import Link from 'next/link'
import { useState } from 'react'
import tw from 'twin.macro'

export default function LoginPage() {
  const { authStatus } = useAuth()
  const login = useLogin()

  const { usernameInputRef, passwordInputRef, submit, error } = login

  const hasUsernameError = !!error && error.toString().includes('username')
  const hasPasswordError = !!error && error.toString().includes('password')

  return (
    <RedirectAuthed to="/">
      <StyledLoginPage>
        <LoginForm onSubmit={submit}>
          <Logo />

          <Input ref={usernameInputRef} placeholder="Username" hasError={hasUsernameError} />
          <Input ref={passwordInputRef} type="password" placeholder="Password" hasError={hasPasswordError} />
          <StyledButton type="submit" loading={authStatus === AuthStatus.LOADING}>
            Login
          </StyledButton>
          <SwitchText>
            New here? <Link href={'/register'}>Create an account</Link>
          </SwitchText>

          {error && <ErrorText>{error.toString()}</ErrorText>}
        </LoginForm>
      </StyledLoginPage>
    </RedirectAuthed>
  )
}

const StyledLoginPage = styled.div(tw`
  flex
  flex-col
  justify-center
  items-center
  h-full
`)

const LoginForm = styled.form(tw`
  flex
  flex-col
  w-64
`)

const Logo = styled(ZalandoLogo)(tw`
  w-full
  mb-16
`)

const Input = styled.input(({ hasError }) => [
  tw`
  w-full
  h-12
  mb-4
  border-black
  border-2
  text-base
  px-2
  outline-none
`,
  hasError && tw`border-zalando-error`,
])

const StyledButton = styled(Button)(tw`
  w-full
  h-12
  bg-black
  hover:bg-zalando-orange
  focus:bg-zalando-orange
  active:bg-black
  text-white
  font-bold
  outline-none
  focus:outline-none
`)

const SwitchText = styled.p(tw`
  w-full
  mt-4
  text-center
`)

const ErrorText = styled.div(tw`
  w-full
  mt-4
  text-center
  text-zalando-error
`)
